/* GoldMinerGame.css */
#gameArea {
	position: relative;
	width: 100vw;
	height: 100vh;
	background-color: #222;
	padding-top: 50px;
	overflow: hidden;
	background-image: url('/public/background-space.jpg');
	background-size: cover;
  }
  #scoreboard {
	position: absolute;
	top: 10px;
	right: 10px;
	color: #fff;
	font-size: 20px;
	z-index: 1000;
	display: grid;
  }
  #pauseButton {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1000;
	padding: 10px 20px;
	font-size: 18px;
  }
  #modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0);
	background-color: rgba(0, 0, 0, 0.8);
	padding: 30px;
	color: #fff;
	font-size: 24px;
	text-align: center;
	border-radius: 10px;
	transition: transform 0.3s ease-in-out;
	z-index: 1001;
  }
  #modal.show {
	transform: translate(-50%, -50%) scale(1);
	width:100%;
  }
  #modal button {
	margin-top: 20px;
	padding: 10px 20px;
	font-size: 18px;
  }

  .cosmonautImage-center {
	background-image: url('../images/cosmonaut.svg');
	width: 100px;
	height: 89px;
	background-size: contain;
	position: absolute;
	top: 0;
	left: 50%;
	margin-top: 10px;
	transform: translate(-50%, 0);
  }


  /* Modal container */
#modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
	background-blend-mode: multiply,multiply; /* Vibrant gradient background */
	border-radius: 15px;
	padding: 20px;
	width: 90%;
	max-width: 400px;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4); /* Add shadow for depth */
	text-align: center;
	z-index: 999;
	color: white;
	font-family: 'Press Start 2P', cursive; /* Add a game-like font */
	animation: modalFadeIn 0.5s ease;
  }
  
  #modalContent {
	margin-bottom: 20px;
	font-size: 1.2em;
	color:black !important;
  }
  #modalContent p {
	margin-bottom: 20px;
	font-size: 1.2em;
	color:black !important;
  }
  
  /* Button styles */
  #modalBtn, #backBtn {
	background-color: #03a9f4;
	border: 2px solid #0288d1;
	color: white;
	font-size: 1em;
	padding: 12px 25px;
	cursor: pointer;
	margin: 10px 0;
	width: 100%;
	border-radius: 8px;
	font-family: 'Press Start 2P', cursive;
	transition: all 0.3s ease; /* Smooth transition */
  }
  
  /* Hover effect for buttons */
  #modalBtn:hover, #backBtn:hover {
	background-color: #19cee6; /* Slightly darker on hover */
	transform: scale(1.05); /* Grow slightly when hovered */
  }
  
  /* Disabled button styles */
  #modalBtn:disabled {
	background-color: #9e9e9e;
	border: 2px solid #757575;
	cursor: not-allowed;
  }
  
  /* Back button specific style */
  #backBtn {
	background-color: #ff5722; /* Button color */
	border: 2px solid #d84315; /* Button border */
  }
  
  #backBtn:hover {
	background-color: #e50303;
  }
  
  /* Animation for modal appearance */
  @keyframes modalFadeIn {
	from {
	  opacity: 0;
	  transform: translate(-50%, -60%);
	}
	to {
	  opacity: 1;
	  transform: translate(-50%, -50%);
	}
  }
 